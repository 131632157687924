import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const PhilosophyPage = () => {
  return (
    <Layout hero={<HeroImage title="Approach" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <div className="mb-5">
            <Link className="back-to-link" to='/community/about-us'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to about us
            </Link>
          </div>
          <Row className="d-flex align-items-center mt-5 mb-5">
            <Col md={8}>
              <Row>
                <Col sm={12} className="mb-3">
                  <div className="title-container mb-3">
                    <h4 className="title">
                      Team Work
                    </h4>
                  </div>
                  <p>Everyone works as a team. We value friendliness, trust, people going out of their way to help each other and banding together to improve Orrcon Steel. We don't tolerate “them and us” attitudes, silos, backstabbing, blaming others or too much “me talk”.</p>
                </Col>
                <Col sm={12} className="mb-3">
                  <div className="title-container mb-3">
                    <h4 className="title">
                      Customers
                    </h4>
                  </div>
                  <p>Everyone has internal and external customers. We use feedback to measure satisfaction and identify improvement opportunities. We don't tolerate poor service, poor quality or lack of timeliness.</p>
                </Col>
                <Col sm={12} className="mb-3">
                  <div className="title-container mb-3">
                    <h4 className="title">
                      Commitment
                    </h4>
                  </div>
                  <p>Everyone pulls their weight. We value people getting in and doing the job at hand. We don't tolerate bludging, slackness, “don't care” attitudes, poor attendance or lack of accountability.</p>
                </Col>
                <Col sm={12}>
                  <div className="title-container mb-3">
                    <h4 className="title">
                      Partnerships
                    </h4>
                  </div>
                  <p>By delivering on our promises, trying harder, delivering a personal touch, being easy to do business with, trying to understand our customers business needs' and providing an effective supply source, we are working in partnership with each other and our customers.</p>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <StaticImage
                src="../../images/honeycomb-pipe.jpeg"
                alt="Tube & Pipe"
                width={340}
                height={340}
                placeholder="blurred"
                layout="fixed" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default PhilosophyPage

export const Head = () => <title>Approach | Orrcon Steel</title>
